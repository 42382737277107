.content {
  display: flex;
  justify-content: space-around;
}

.settings {
  width: 45%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.banner {
  width: 55%;
  align-self: center;
  padding: 8px;
}

@media (max-width: 800px) {
  .content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .banner {
    width: 85%;
    padding: 0;
    padding-top: 20px;
  }
  .settings {
    width: 100%;
  }
}

.settings__select {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  flex: 0.8;
  justify-content: space-evenly;
}

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin; /* Firefox */
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: grey;
}

a {
  text-decoration: inherit;
  color: inherit;
}

.app {
  min-height: 98.5vh;
  border: 8px solid #39445a93;
  /* background-color: white; */
  margin: 5px;
  padding: 5px;
}

.mainImg {
  padding: 10px 0 5px 0;
}

.title {
  text-transform: uppercase;
  font-size: 8vw;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  margin: 10px;
  width: 100%;
  background-color: grey;
}

.content {
  display: flex;
  justify-content: space-around;
}

.settings {
  width: 45%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.banner {
  width: 55%;
  align-self: center;
  padding: 8px;
}

@media (max-width: 800px) {
  .content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .banner {
    width: 85%;
    padding: 0;
    padding-top: 20px;
  }
  .settings {
    width: 100%;
  }
}

.settings__select {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  flex: 0.8 1;
  justify-content: space-evenly;
}

.question {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.singleQuestion {
  width: 95%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 5px solid grey;
  padding: 20px;
  margin-top: 10px;
}

.options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
}

.singleOption {
  width: 46%;
  height: 50px;
  padding: 15px 20px;
  margin: 10px;
  box-shadow: 0 0 2px black;
}

@media (max-width: 614px) {
  .singleOption {
    width: 100%;
  }
}

.controls {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.select {
  background-color: rgb(7, 207, 0);
  color: white;
  box-shadow: 0 0 1px black;
}

.wrong {
  background-color: rgb(233, 0, 0);
  color: white;
  box-shadow: 0 0 1px black;
}

.quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.subtitle {
  font-size: 25px;
  border: 1px solid black;
  box-shadow: 4px 4px 2px black;
  padding: 5px 10px;
}

.quizInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 10px;
}

.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
  text-align: center;
}


.question {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.singleQuestion {
  width: 95%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 5px solid grey;
  padding: 20px;
  margin-top: 10px;
}

.options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
}

.singleOption {
  width: 46%;
  height: 50px;
  padding: 15px 20px;
  margin: 10px;
  box-shadow: 0 0 2px black;
}

@media (max-width: 614px) {
  .singleOption {
    width: 100%;
  }
}

.controls {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.select {
  background-color: rgb(7, 207, 0);
  color: white;
  box-shadow: 0 0 1px black;
}

.wrong {
  background-color: rgb(233, 0, 0);
  color: white;
  box-shadow: 0 0 1px black;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin; /* Firefox */
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: grey;
}

a {
  text-decoration: inherit;
  color: inherit;
}

.app {
  min-height: 98.5vh;
  border: 8px solid #39445a93;
  /* background-color: white; */
  margin: 5px;
  padding: 5px;
}

.mainImg {
  padding: 10px 0 5px 0;
}
